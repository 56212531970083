import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'toDateObj',
  standalone: true,
})
export class ToDateObjPipe implements PipeTransform {

  transform(value: any): null | Date {

    if (value) {
      const temp = value.toString().replace(' ', 'T')
      const date = new Date(temp)
      if(!isNaN(date.getTime())) {
        return date
      } else {
        return null
      }
    } else {
      return null
    }
  }

}
